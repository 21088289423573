/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as EyeIcon } from "../../art/Eye.svg";
import { ReactComponent as HideEyeIcon } from "../../art/HideEye.svg";

import "./ToggleFieldVisibility.scss";

const ToggleFieldVisibility = ({ onView }) => {
  const [viewField, setViewField] = useState(false);

  const toggeleEyeIcon = () => {
    setViewField(!viewField);
    onView && onView(!viewField);
  };

  const iconClass = clsx("toggleFieldVisibility__icon");
  const icon = viewField ? EyeIcon : HideEyeIcon;
  return (
    <div className={iconClass} onClick={toggeleEyeIcon}>
      <SvgIcon component={icon} fontSize="large" />
    </div>
  );
};

ToggleFieldVisibility.propTypes = {
  onView: PropTypes.func,
};

export default ToggleFieldVisibility;
