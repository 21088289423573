/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react";
import PropTypes from "prop-types";

import TextArea from "../TextArea";

const FormikTextArea = ({ field, ...otherProps }) => {
  return <TextArea {...field} {...otherProps} />;
};

FormikTextArea.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    container: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  enableCopy: PropTypes.bool,
  field: PropTypes.object,
  label: PropTypes.string,
  minLines: PropTypes.number,
  maxLines: PropTypes.number,
  noResize: PropTypes.bool,
};

FormikTextArea.defaultProps = {
  classes: {},
  disabled: false,
  enableCopy: false,
  field: {},
  label: null,
  minLines: 1,
  maxLines: 3,
  noResize: false,
};

export default FormikTextArea;
